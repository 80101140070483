import common from './common.json';
import documentAgreement from './documentAgreement.json';
import visitDetails from './visitDetails.json';
import languageSelector from './languageSelector.json';

export const frCa = {
  common,
  documentAgreement,
  languageSelector,
  visitDetails,
};
