/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum GenericHealthCheckpointStatus {
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  INCOMPLETE = "INCOMPLETE",
  PASSED = "PASSED",
  UNKNOWN = "UNKNOWN",
}

export enum GuestInviteStatus {
  CANCELED = "CANCELED",
  CHECKED_IN = "CHECKED_IN",
  CHECKED_OUT = "CHECKED_OUT",
  COMPLETED_REQUIREMENTS = "COMPLETED_REQUIREMENTS",
  EXPECTED = "EXPECTED",
  INVITED = "INVITED",
  PENDING_INVITE = "PENDING_INVITE",
}

export enum VisitDocumentAgreementStatus {
  ACCEPT = "ACCEPT",
}

export enum VisitDocumentCompletionStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
}

export enum VisitType {
  CUSTOMER_VISIT = "CUSTOMER_VISIT",
  EVENT = "EVENT",
  JOB_INTERVIEW = "JOB_INTERVIEW",
  MAINTENANCE = "MAINTENANCE",
  OTHER = "OTHER",
  VIP_VISIT = "VIP_VISIT",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
