import { useState, useEffect } from 'react';
import moment from 'moment';

/**
 * Returns the current time, updating automatically.
 * Caution: This will cause state updates every second.
 *
 * @param stopTime The time after which to stop updating
 * @return The current time
 */
export function useCurrentTime(stopTime?: moment.Moment): moment.Moment {
  const [currentMoment, setCurrentMoment] = useState(() => moment());

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    const updateTime = () => {
      const now = moment();
      if (stopTime && now.isAfter(stopTime) && interval) {
        clearInterval(interval);
      }
      setCurrentMoment(now);
    };

    interval = setInterval(updateTime, 1000);
    return () => interval && clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return currentMoment;
}
