import { Box } from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import { ReactNode } from 'react';

type PropTypes = {
  role?: string;
  children: ReactNode;
  backgroundColor?: string;
};

export const PageSection = ({
  role,
  backgroundColor,
  children,
}: PropTypes): JSX.Element => (
  <Box role={role} padding="24px" backgroundColor={backgroundColor}>
    <CenteredContent>{children}</CenteredContent>
  </Box>
);

const CenteredContent = styled.div`
  max-width: 560px;
  width: 100%;
  margin: 0 auto;
`;
