import { gql, useQuery, QueryHookOptions, QueryResult } from '@apollo/client';
import {
  GetVisitDocumentsWithCustomVisitType,
  GetVisitDocumentsWithCustomVisitTypeVariables,
} from './__generated__/GetVisitDocumentsWithCustomVisitType';

export const GET_VISIT_DOCUMENTS = gql`
  query GetVisitDocumentsWithCustomVisitType(
    $visitInviteId: ID!
    $visitType: String!
    $locationId: ID!
  ) {
    visitOverviewOfDocumentsWithCustomVisitType(
      visitInviteId: $visitInviteId
      visitType: $visitType
      locationId: $locationId
    ) {
      id
      documents {
        id
        name
        isRequired
        completionStatus
      }
    }
  }
`;

export function useGetVisitDocuments(
  visitInviteId: string,
  visitType: string,
  locationId: string,
  options?: QueryHookOptions<
    GetVisitDocumentsWithCustomVisitType,
    GetVisitDocumentsWithCustomVisitTypeVariables
  >
): QueryResult<
  GetVisitDocumentsWithCustomVisitType,
  GetVisitDocumentsWithCustomVisitTypeVariables
> {
  return useQuery<
    GetVisitDocumentsWithCustomVisitType,
    GetVisitDocumentsWithCustomVisitTypeVariables
  >(GET_VISIT_DOCUMENTS, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: {
      visitInviteId,
      visitType,
      locationId,
    },
  });
}
