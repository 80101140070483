import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV;

Sentry.init({
  enabled: SENTRY_ENV !== 'development',
  environment: SENTRY_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

export { Sentry };
