import { ReactNode, useContext, useMemo, useState, createContext } from 'react';
import {
  ApolloProvider as ApolloClientProvider,
  ApolloClient,
  from,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache/';
import { createHttpLink } from '@apollo/client/link/http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Severity } from '@sentry/types';
import { Sentry } from 'lib/sentry';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GQL_URL ?? '',
  fetch, // Fixes https://sentry.io/organizations/robin/issues/2779273607
});

const cache = new InMemoryCache();

type ApolloContextValue = {
  tenantId: string | null;
  authToken: string | undefined;
  setAuthToken: (bearerToken: string) => void;
  setTenantId: (tenantId: string) => void;
};

const ApolloContext = createContext<ApolloContextValue | undefined>(undefined);

export const ApolloProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [bearerToken, setBearerToken] = useState<string | undefined>();
  const [tenantId, setTenantId] = useState<string | null>(null);

  const client = useMemo(() => {
    const authLink = setContext((_, { headers: _headers }) => {
      const headers = {
        ..._headers,
        authorization: bearerToken ? `Bearer ${bearerToken}` : '',
      };
      if (tenantId) {
        headers['tenant-id'] = tenantId;
      }
      return {
        headers,
      };
    });

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, response }) => {
        const captureException = (
          message: string,
          level: Severity,
          extra?: Record<string, unknown>
        ) => {
          Sentry.withScope((scope) => {
            scope.setLevel(level);
            Sentry.captureException(message, {
              tags: {
                graphql: true,
              },
              extra: {
                ...extra,
                operation,
                response,
              },
            });
          });
        };

        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) => {
            captureException(
              `[GraphQL error]: Message: ${message}`,
              Sentry.Severity.Error,
              {
                path,
                locations,
              }
            );
          });
        }

        if (networkError) {
          captureException(
            `[Network error]: ${networkError}`,
            Sentry.Severity.Info
          );
        }
      }
    );

    return new ApolloClient({
      link: from([errorLink, authLink, httpLink]),
      name: 'guest-ui',
      cache,
      credentials: 'include',
      resolvers: {},
    });
  }, [bearerToken, tenantId]);

  const value = {
    setAuthToken: setBearerToken,
    setTenantId,
    tenantId,
    authToken: bearerToken,
  };

  return (
    <ApolloContext.Provider value={value}>
      <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
    </ApolloContext.Provider>
  );
};

export function useApolloContext(): ApolloContextValue {
  const context = useContext(ApolloContext);
  if (!context) {
    throw new Error('useApolloContext must be used within a ApolloProvider');
  }
  return context;
}
