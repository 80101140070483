import { Colors, Row } from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';

export const MessageContainer = styled(Row)`
  background-color: ${Colors.Tan5};
  border-radius: 12px;
  padding: 16px 16px 16px 12px;
  align-items: center;
  justify-content: flex-start;
`;
