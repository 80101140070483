import moment from 'moment-timezone';
import { i18n } from 'i18n';

export const formatStartTime = (
  startTime: string,
  timezone: string | null
): string => {
  if (!timezone) {
    timezone = moment.tz.guess();
  }

  const date = moment.tz(startTime, timezone);
  let datePart = '';

  if (date.isSame(moment.tz(timezone), 'day')) {
    datePart = i18n.t('visitDetails:today');
  } else {
    datePart = date.format('ddd, MMM DD');
  }

  return `${datePart} @ ${date.format('h:mma')}`;
};
