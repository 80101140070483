import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { Box, Colors } from '@robinpowered/design-system';
import { GetVisitQuery_getVisitByMagicLink } from 'pages/VisitDetails/contexts/__generated__/GetVisitQuery';
import { formatStartTime } from '../utils';

export const VisitFields = ({
  visit,
}: {
  visit: GetVisitQuery_getVisitByMagicLink;
}): JSX.Element => {
  const { t } = useTranslation('visitDetails');

  return (
    <>
      <Field aria-labelledby="section-host">
        <FieldLabel id="section-host">{t('sections.details.host')}</FieldLabel>
        {visit.host.name ?? t('sections.details.unknown_host')}
      </Field>
      <Field aria-labelledby="section-where">
        <FieldLabel id="section-where">
          {t('sections.details.where')}
        </FieldLabel>
        {visit.arrivalLocation.name}
        {visit.arrivalLocation.address && (
          <Box mt={1}>{visit.arrivalLocation.address}</Box>
        )}
      </Field>
      <Field aria-labelledby="section-when">
        <FieldLabel id="section-when">{t('sections.details.when')}</FieldLabel>
        {formatStartTime(visit.startTime, visit.arrivalLocation.timezone)}
      </Field>
      <Field last aria-labelledby="section-why">
        <FieldLabel id="section-why">{t('sections.details.why')}</FieldLabel>
        {visit.customVisitType}
      </Field>
    </>
  );
};

const Field = styled.div<{ last?: boolean }>`
  font-size: 18px;

  ${(props) =>
    !props.last &&
    `
    border-bottom: 2px solid ${Colors.Tan10};
    margin: 16px 0;
    padding-bottom: 16px;
  `}
`;

const FieldLabel = styled.div`
  color: ${Colors.Gray90};
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
`;
