import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { Button } from '@robinpowered/design-system';
import { useAmplitude } from 'contexts';
import LockSVG from './lock-solid.svg';

export const CheckInButton = ({
  onPress,
  isLoading,
  disabled = false,
}: {
  onPress?: () => void;
  isLoading: boolean;
  disabled?: boolean;
}): JSX.Element => {
  const { t } = useTranslation('visitDetails');
  const { trackCheckIn } = useAmplitude();

  const onCheckIn = () => {
    onPress?.();
    trackCheckIn();
  };

  return (
    <PrimaryButton
      variant="primary"
      size="large"
      isLoading={isLoading}
      onClick={onCheckIn}
      disabled={disabled}
    >
      {disabled && <LockIcon />}
      {t('sections.check_in.cta')}
    </PrimaryButton>
  );
};

const LockIcon = () => (
  <img style={{ marginRight: '4px' }} src={LockSVG} alt="disabled" />
);

const PrimaryButton = styled(Button)`
  width: 100%;
  margin-top: 12px;
  box-shadow: none;
`;
