import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled/macro';
import {
  Colors,
  Button,
  SpinnerLoader,
  Body05,
  Heading02,
  Heading06,
  Checkbox,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { useDocumentDetails } from './hooks/useDocumentDetails';
import { useAgreeToDocument } from './hooks/useAgreeToDocument';
import { useHasViewedRef } from './hooks/useHasViewedRef';
import { useVisitDetailsContext } from 'pages/VisitDetails/contexts/VisitDetailsContext';
import { ErrorWithRetry } from 'components/ErrorWithRetry';
import { useMinimumLoading } from 'hooks/useMinimumLoading';
import { Sentry } from 'lib/sentry';
import {
  VisitDocumentAgreementStatus,
  VisitDocumentCompletionStatus,
} from '__generated__/globalTypes';

enum ScreenState {
  LOADING,
  ERROR,
  SUCCESS,
}

export const DocumentAgreement: React.FC = () => {
  const navigate = useNavigate();
  const { documentId, uuid: magicLink } = useParams<{
    documentId: string;
    uuid: string;
  }>();
  const { t } = useTranslation('documentAgreement');
  const [screenState, _setScreenState] = useState(ScreenState.LOADING);
  const [sendCopyToGuest, setSendCopyToGuest] = useState<boolean>(false);
  const setScreenState = useCallback(
    (state: ScreenState) => {
      if (screenState !== state) _setScreenState(state);
    },
    [screenState, _setScreenState]
  );

  // Allow us detect whether the full document has been viewed yet.
  // We're putting an empty div at the bottom of the document and detecting if
  // and when it enters the viewport.
  const { hasViewedRef, setRef } = useHasViewedRef();
  const documentBottomRef = useRef<HTMLElement | null>(null);
  const refCallback = useCallback(
    (node) => {
      documentBottomRef.current = node;
      setRef(node);
    },
    [setRef]
  );
  const scrollToBottom = () => {
    documentBottomRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const { data: visit } = useVisitDetailsContext();
  const {
    data,
    loading: _loadingDocument,
    refetch,
    error,
  } = useDocumentDetails(
    visit?.customVisitType,
    visit?.arrivalLocation.id,
    visit?.id,
    documentId,
    { skip: !visit }
  );
  const isLoadingDocument = useMinimumLoading(_loadingDocument);
  const document =
    data?.visitDocumentWithNextDocumentIdWithCustomVisitType ?? null;
  const documentAlreadyCompleted =
    document?.completionStatus === VisitDocumentCompletionStatus.COMPLETE;
  const agreeOutcome = useMemo(
    () =>
      document?.possibleOutcomes.find(
        (o) => o.outcome === VisitDocumentAgreementStatus.ACCEPT
      ),
    [document]
  );

  useEffect(() => {
    if (isLoadingDocument) {
      setScreenState(ScreenState.LOADING);
    } else if (error || !document || !agreeOutcome) {
      setScreenState(ScreenState.ERROR);
    } else if (document) {
      setScreenState(ScreenState.SUCCESS);
    }
    if (documentAlreadyCompleted) {
      navigate(-1);
    }
  }, [
    setScreenState,
    navigate,
    document,
    error,
    isLoadingDocument,
    agreeOutcome,
    documentAlreadyCompleted,
  ]);

  const [agreeMutation, { loading: agreementLoading }] = useAgreeToDocument();
  const agreeToDocument = () => {
    if (visit && document && agreeOutcome) {
      agreeMutation({
        variables: {
          visitId: visit.visitId,
          visitInviteId: visit.id,
          guestId: visit.guest.id,
          workflowId: document.workflowId,
          outcomeId: agreeOutcome?.id,
          sendCopyToGuest,
        },
      })
        .catch(() => {
          setScreenState(ScreenState.ERROR);
        })
        .then(() => {
          navigate(`/visit/${magicLink}`, { replace: true });
        });
    } else {
      // Some missing state stopped us from being able to agree to the document
      setScreenState(ScreenState.ERROR);
      Sentry.captureMessage(
        `Missing data blocked document agreement: ${
          (!visit && 'visit,') ||
          (!document && 'document,') ||
          (!agreeOutcome && 'agreeOutcome,')
        }`
      );
    }
  };

  return (
    <ScreenContainer>
      <ScreenHeaderContainer>
        <Heading02 mb="2px">{t('agreement_header')}</Heading02>
        <DocumentName isHidden={!document?.documentName}>
          {document?.documentName || 'document'}
        </DocumentName>
      </ScreenHeaderContainer>
      <BodyContainer>
        {screenState === ScreenState.LOADING && <Loader />}
        {screenState === ScreenState.ERROR && (
          <ErrorWithRetry
            retry={refetch}
            message={t('document_error_message')}
          />
        )}
        {screenState === ScreenState.SUCCESS && (
          <>
            <DocumentContainer>
              <DocumentText>{document?.documentBody}</DocumentText>
              {/* Empty div for detecting if the user read the whole document */}
              <div ref={refCallback} />
            </DocumentContainer>

            <Footer>
              {hasViewedRef ? (
                <CenteredColumn>
                  <Body05
                    mb="16px"
                    p="24px"
                    style={{
                      backgroundColor: Colors.Tan5,
                      borderRadius: '6px',
                      textAlign: 'center',
                    }}
                  >
                    {t('agree_description', { guestName: visit?.guest.name })}
                  </Body05>
                  <CheckboxWrapper>
                    <Checkbox
                      id="guestCopyCheckbox"
                      label={t('send_guest_copy')}
                      handleClick={() => setSendCopyToGuest(!sendCopyToGuest)}
                      checked={sendCopyToGuest}
                      disabled={false}
                      indeterminate={false}
                      py={2}
                    />
                    <Button
                      isLoading={agreementLoading}
                      onClick={agreeToDocument}
                      size="large"
                      style={{
                        marginLeft: 32,
                        padding: '12px 50px',
                        flex: 1,
                      }}
                    >
                      {t('agree')}
                    </Button>
                  </CheckboxWrapper>
                </CenteredColumn>
              ) : (
                <WideButton variant="secondary" onClick={scrollToBottom}>
                  {t('scroll_to_bottom')}
                </WideButton>
              )}
            </Footer>
          </>
        )}
      </BodyContainer>
    </ScreenContainer>
  );
};

const Loader = () => (
  <DocumentContainer
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '100px',
    }}
  >
    <SpinnerLoader size={28} />
  </DocumentContainer>
);

const ScreenContainer = styled.div`
  background-color: ${Colors.Tan30};
  height: 100%;
  max-width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: auto;
  padding-right: auto;
  overflow: auto;
`;

const ScreenHeaderContainer = styled.div`
  padding: 58px 22px 22px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DocumentContainer = styled.div`
  overflow: auto; /* Make long documents scroll */
  flex: 1;
  align-self: stretch;
  max-width: 100%;
  background-color: ${Colors.White0};
  padding: 32px 32px 0px 32px;
  border-radius: 8px;
  @media screen and (max-width: 560px) {
    border-radius: 8px 8px 0 0;
  }
`;

const DocumentText = styled(Body05)`
  display: block; /* Allows long words to be split */
  white-space: pre-wrap; /* Support for line breaks */
  overflow-wrap: break-word; /* Allows long words to be split */
  color: ${Colors.Gray100};
  margin-bottom: 160px; /* Allows the document to auto-scroll all the way to the bottom on mobile */
`;

const DocumentName = styled(Heading06)`
  text-align: center;
  color: ${Colors.Gray40};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

const BodyContainer = styled.div`
  overflow-y: auto; /* Allow contents to scroll */
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 560px;
  width: 100%;
  align-self: center;
  align-items: center;
  justify-content: center; /* Center the loader */
  border-radius: 8px 8px 0px 0px;
  white-space: pre-wrap; /* Support for line breaks */
`;

const WideButton = styled(Button)`
  background-color: ${Colors.White0};
  font-size: 18px;
  padding: 12px;
  flex: 1;
  width: 100%;
  @media screen and (min-width: 560px) {
    margin-left: -24px; /* Make it full page width */
    margin-right: -24px; /* Make it full page width */
  }
`;

const CenteredColumn = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  label {
    color: ${Colors.Gray80};
  }
`;

const Footer = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
  padding: 24px;
  @media screen and (max-width: 560px) {
    background-color: ${Colors.White0};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04),
      0px 1px 20px rgba(0, 0, 0, 0.1);
  }
`;
