import { Box, Colors } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { PageSection } from './components/PageSection';
import { Section } from './components/Section';
import { SectionTitle } from './components/SectionTitle';

export const NotFound = (): JSX.Element => {
  const { t } = useTranslation('visitDetails');
  return (
    <PageSection backgroundColor={Colors.White0}>
      <Section mt="4" light>
        <SectionTitle>
          {t('sections.error.visit_not_found.header')}
        </SectionTitle>
        <Box mb="3">{t('sections.error.visit_not_found.body')}</Box>
        {t('sections.error.visit_not_found.body2')}
      </Section>
    </PageSection>
  );
};
