import { useTranslation } from 'react-i18next';
import {
  Colors,
  Button,
  Heading04,
  Body04Styles,
  Body04,
  Body05,
  Skeleton,
} from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import RightChevronSVG from './chevron-right-outline.svg';
import CircleCheckmarkSVG from './circle-checkmark-solid.svg';
import { GetVisitQuery_getVisitByMagicLink as VisitData } from '../../contexts/__generated__/GetVisitQuery';
import { useActions, ActionItem, Status } from './hooks/useActions';

export const ActionItemsSection: React.FC<{
  visit: VisitData;
}> = ({ visit }) => {
  const { actionItems, loading, error, refetch } = useActions(visit);

  if (loading) {
    return <ActionItemsLoadingUI />;
  }

  if (error) {
    return <ActionItemsErrorUI refetch={refetch} />;
  }

  return <ActionItemsUI actionItems={actionItems} />;
};

export const ActionItemsUI: React.FC<{
  actionItems: ActionItem[];
}> = ({ actionItems }) => (
  <Container>
    {actionItems.map((item) => (
      <ItemButton
        key={item.label}
        variant="tertiary"
        onClick={item.onPress}
        status={item.status}
        disabled={
          item.status === Status.COMPLETED || item.status === Status.DISABLED
        }
      >
        <div style={{ overflow: 'hidden' }}>
          {item.disabledMessage && item.status === Status.DISABLED && (
            <Body05 color={Colors.Gray100}>{item.disabledMessage}</Body05>
          )}
          <ItemButtonText>{item.label}</ItemButtonText>
        </div>
        {item.status === Status.COMPLETED ? (
          <RightIcon svg={CircleCheckmarkSVG} alt="checkmark, checked" />
        ) : (
          <RightIcon svg={RightChevronSVG} alt="right arrow" />
        )}
      </ItemButton>
    ))}
  </Container>
);

export const ActionItemsLoadingUI: React.FC = () => {
  const LoadingRow = () => (
    <LoadingItem>
      <HideText>This sets the height</HideText>
      <Skeleton width={200} height={16} style={{ position: 'absolute' }} />
      <Skeleton width={14} height={14} mr="5px" />
    </LoadingItem>
  );
  return (
    <Container>
      <LoadingRow />
      <LoadingRow />
    </Container>
  );
};

const ActionItemsErrorUI: React.FC<{ refetch: () => unknown }> = ({
  refetch,
}) => {
  const { t } = useTranslation('visitDetails', {
    keyPrefix: 'sections.check_in.actions',
  });
  return (
    <Container style={{ alignItems: 'center' }}>
      <Heading04 mt="48px" mb="8px">
        {t('error_title')}
      </Heading04>
      <Body05
        mb="12px"
        mx="12px"
        style={{ textAlign: 'center', whiteSpace: 'pre' }}
      >
        {t('error_body')}
      </Body05>
      <Button variant="secondary" onClick={() => refetch()} mb="48px">
        {t('retry')}
      </Button>
    </Container>
  );
};

const RightIcon = ({ svg, alt }: { svg: string; alt: string }) => (
  <div
    style={{
      marginLeft: '12px',
      // Static height/width to keep size constant across icons
      height: '24px',
      width: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img src={svg} alt={alt} />
  </div>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${Colors.Tan70};
  border-radius: 16px;
  margin: 16px 0;
  overflow: hidden;
`;

const ItemButton = styled(Button)<{ status: Status }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  ${Body04Styles};
  padding: 16px;
  border-radius: 0;
  color: ${({ status }) =>
    status === Status.DISABLED
      ? Colors.Gray40
      : status === Status.COMPLETED
      ? Colors.Gray80
      : Colors.Gray100};
  background-color: ${({ status }) =>
    status === Status.DISABLED ? Colors.Tan5 : 'inherit'};
  text-decoration-line: ${({ status }) =>
    status === Status.COMPLETED ? 'line-through' : 'none'};
  :not(:last-child) {
    border-bottom: 1px solid ${Colors.Tan70};
  }
`;

const ItemButtonText = styled(Body04)`
  color: ${Colors.Gray100};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

const LoadingItem = styled(ItemButton.withComponent('div'))`
  align-items: center;
  justify-content: space-between;
`;

const HideText = styled.div`
  opacity: 0;
`;
