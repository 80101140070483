import {
  gql,
  MutationTuple,
  MutationHookOptions,
  useMutation,
} from '@apollo/client';
import { useParams } from 'react-router-dom';
import { SaveCheckIn, SaveCheckInVariables } from './__generated__/SaveCheckIn';
import { useVisitDetailsContext } from '../../../contexts/VisitDetailsContext';
import { GET_VISIT_QUERY } from 'pages/VisitDetails/contexts/VisitDetailsContext';

export const SAVE_CHECK_IN = gql`
  mutation SaveCheckIn($uuid: String!) {
    checkIntoVisitWithMagicLink(magicLink: $uuid)
  }
`;

export const useCheckInMutation = (
  options?: MutationHookOptions<SaveCheckIn, SaveCheckInVariables>
): MutationTuple<SaveCheckIn, SaveCheckInVariables> => {
  const { uuid } = useParams<{ uuid: string }>();
  const { tenantId } = useVisitDetailsContext();

  return useMutation(SAVE_CHECK_IN, {
    ...options,
    variables: {
      uuid: uuid ?? '',
    },
    refetchQueries: () => [
      {
        query: GET_VISIT_QUERY,
        variables: {
          uuid,
        },
        context: {
          headers: {
            'tenant-id': tenantId,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });
};
