import { AmplitudeProvider, ApolloProvider } from 'contexts';
import { AppRouter } from './AppRouter';

export function App(): JSX.Element {
  return (
    <ApolloProvider>
      <AmplitudeProvider>
        <AppRouter />
      </AmplitudeProvider>
    </ApolloProvider>
  );
}
