import { useNavigate, useLocation } from 'react-router-dom';
import { parse } from 'query-string';

type Params = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

type UseQueryParams = {
  params: Params;
  clearParams: () => void;
  setParams: (params: Params) => void;
};

export const useQueryParams = (): UseQueryParams => {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const params = parse(routerLocation.search);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setParams = (newParams: Record<string, any>) => {
    navigate({
      pathname: routerLocation.pathname,
      search: `?${new URLSearchParams({
        ...newParams,
      }).toString()}`,
    });
  };

  const clearParams = () => {
    navigate({
      pathname: routerLocation.pathname,
      search: '',
    });
  };

  return {
    params,
    clearParams,
    setParams,
  };
};
