import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { GetVisitQuery_getVisitByMagicLink as VisitData } from '../../../contexts/__generated__/GetVisitQuery';
import {
  GenericHealthCheckpointStatus,
  VisitDocumentCompletionStatus,
} from '__generated__/globalTypes';
import { useGetVisitDocuments } from './useGetVisitDocuments';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';

const HCP_AVAILABILITY_WINDOW_HOURS = 24;

/**
 * All the Action Item types
 */
export enum Action {
  'HEALTH_CHECK' = 'HEALTH_CHECK',
  'DOCUMENT_AGREEMENT' = 'DOCUMENT_AGREEMENT',
}

export enum Status {
  'COMPLETED' = 'COMPLETED',
  'INCOMPLETE' = 'INCOMPLETE',
  'DISABLED' = 'DISABLED',
}

/**
 * Something that must be completed before you can check in.
 */
export type ActionItem = {
  action: Action;
  label: string;
  disabledMessage?: string; // A sub-label to show when the status is disabled
  status: Status;
  onPress: () => void;
};

/**
 * A hook which returns all the actions that a user can complete for the given visit.
 */
export const useActions = (
  visit: VisitData
): {
  actionItems: ActionItem[];
  loading: boolean;
  error?: ApolloError;
  refetch: () => unknown;
} => {
  const actionItems: ActionItem[] = [];
  const { t } = useTranslation('visitDetails');
  const navigate = useNavigate();

  /*
   * Actions for any documents to agree to
   */
  const {
    data: docData,
    loading,
    error,
    refetch,
  } = useGetVisitDocuments(
    visit.id,
    visit.customVisitType,
    visit.arrivalLocation.id
  );
  const documents =
    docData?.visitOverviewOfDocumentsWithCustomVisitType?.documents ?? [];
  documents.forEach((document) => {
    const status =
      document.completionStatus === VisitDocumentCompletionStatus.COMPLETE
        ? Status.COMPLETED
        : Status.INCOMPLETE;
    const onPress =
      status === Status.INCOMPLETE
        ? () => navigate(`document/${document.id}`)
        : () => {}; // no-op
    actionItems.push({
      action: Action.DOCUMENT_AGREEMENT,
      label: document.name,
      status,
      onPress,
    });
  });

  /*
   * Action for the health checkpoint survey
   */
  const isHealthCheckRequired =
    !!visit.arrivalLocation.isHealthCheckpointRequired;
  const isHealthCheckCreated = visit.healthCheckpointSurveyResponse;
  const isVisitOutsideHCPWindow = moment(visit.startTime).isAfter(
    moment().add(HCP_AVAILABILITY_WINDOW_HOURS, 'hours')
  );
  if (isHealthCheckCreated) {
    const healthCheckStatus = visit.healthCheckpointSurveyResponse?.status;
    const surveyId = visit.healthCheckpointSurveyResponse?.id;
    const surveyURL = `${process.env.REACT_APP_HEALTH_SURVEY_BASE_URL}${surveyId}`;
    const status =
      healthCheckStatus === GenericHealthCheckpointStatus.INCOMPLETE
        ? Status.INCOMPLETE
        : Status.COMPLETED;
    const onPress =
      status === Status.INCOMPLETE
        ? () => window.open(surveyURL, '_blank')?.focus() // Open in a new tab and focus it
        : () => {}; // no-op
    actionItems.push({
      action: Action.HEALTH_CHECK,
      label: t('sections.check_in.actions.health_checkpoint'),
      status,
      onPress,
    });
  } else if (
    isHealthCheckRequired &&
    !isHealthCheckCreated &&
    isVisitOutsideHCPWindow
  ) {
    // Health checks don't get created until ~24 hours out from the reservation
    // The user still needs to complete the health check, but later once it's created
    const timeWhenAvailable = moment(visit.startTime).subtract(
      HCP_AVAILABILITY_WINDOW_HOURS,
      'hours'
    );
    actionItems.push({
      action: Action.HEALTH_CHECK,
      label: t('sections.check_in.actions.health_checkpoint'),
      disabledMessage: t(
        'sections.check_in.actions.health_check_available_on_x',
        {
          time: timeWhenAvailable.format('LT'),
          date: timeWhenAvailable.format('MMM D'),
        }
      ),
      status: Status.DISABLED,
      onPress: () => {},
    });
  }

  return { actionItems, loading, error, refetch };
};
