import styled from '@emotion/styled/macro';
import { Box, Colors } from '@robinpowered/design-system';

export const Section = styled(Box)<{ light?: boolean; boxed?: boolean }>`
  flex: 1;
  padding: ${(props) => (props.boxed ? '24px' : '24px 0')};
  background-color: ${Colors.White0};
  white-space: pre-wrap;
  word-break: break-word;

  ${(props) =>
    props.boxed &&
    `
      border: 1px solid ${Colors.Tan20};
      border-radius: 12px;
      box-shadow: ${
        props.light
          ? `0px 4px 10px rgba(0, 0, 0, 0.04), 0px 1px 20px rgba(0, 0, 0, 0.1)`
          : `0px 1px 3px rgba(0, 0, 0, 0.08)`
      };
      `}}
`;

Section.defaultProps = {
  light: false,
  boxed: false,
};
