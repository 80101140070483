import { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { VisitDetails, DocumentAgreement } from 'pages';
import { useApolloContext } from 'contexts';
import { useAuthentication } from 'hooks/useAuthentication';
import { NotFound } from './pages/VisitDetails/NotFound';
import { VisitDetailsProvider } from 'pages/VisitDetails/contexts/VisitDetailsContext';

const VisitRoutes = () => {
  // Authenticates using the `uuid` router param.
  const { uuid: magicLink } = useParams<{ uuid: string }>();
  const [authenticate, { error: authError, loading: authLoading }] =
    useAuthentication(magicLink);
  const { tenantId, authToken } = useApolloContext();
  // Only show the loading screen when we first authenticate.
  // Don't flash it when we're regularly refreshing our bearer token.
  const isWaitingForAuth = authLoading && !authToken;

  useEffect(() => {
    authenticate();
  }, [magicLink, authenticate]);

  // Invalid UUID, visit not found, etc.
  if (authError) {
    return <NotFound />;
  }

  return (
    <VisitDetailsProvider magicLinkUuid={magicLink} tenantId={tenantId}>
      <Routes>
        <Route
          index
          element={<VisitDetails isParentLoading={isWaitingForAuth} />}
        />
        <Route path="document/:documentId" element={<DocumentAgreement />} />
      </Routes>
    </VisitDetailsProvider>
  );
};

export const AppRouter = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/visit/:uuid/*" element={<VisitRoutes />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
