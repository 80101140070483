import { Avatar, Colors, Body04, Body05 } from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import { MessageContainer } from './MessageContainer';

type AvatarEmailProps = {
  avatarSrc?: string;
  avatarName: string;
  avatarEmail: string;
};

export const AvatarNameEmail: React.FC<AvatarEmailProps> = ({
  avatarSrc,
  avatarName,
  avatarEmail,
}) => (
  <MessageContainer>
    {/* Wrapping in an additional component avoids distortion when window is narrow */}
    <AvatarWrapper>
      <Avatar name={avatarName} src={avatarSrc} size={52} />
    </AvatarWrapper>
    <div>
      <Body04 color={Colors.Gray100}>{avatarName}</Body04>
      <Body05 color={Colors.Gray80}>{avatarEmail}</Body05>
    </div>
  </MessageContainer>
);

const AvatarWrapper = styled.div`
  margin-right: 8px;
`;
