import styled from '@emotion/styled/macro';
import { Colors, Skeleton } from '@robinpowered/design-system';
import { PageSection } from './components/PageSection';

export const Loading = (): JSX.Element => {
  return (
    <PageSection role="progressbar" backgroundColor={Colors.White0}>
      <CenterContent>
        {/* Logo */}
        <Skeleton width={50} height={50} marginBottom={50} />
        <Skeleton width={400} height={25} marginBottom={10} maxWidth="100%" />
        <Skeleton width={350} height={25} marginBottom={50} maxWidth="80%" />

        <Skeleton width={400} height={25} marginBottom={10} maxWidth="100%" />
        <Skeleton width={400} height={25} marginBottom={25} maxWidth="100%" />
        <Skeleton width={400} height={25} marginBottom={25} maxWidth="100%" />
      </CenterContent>
    </PageSection>
  );
};

const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
