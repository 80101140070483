import { useQuery, QueryResult, QueryHookOptions } from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  GetVisitDocumentForGuestWithCustomVisitType,
  GetVisitDocumentForGuestWithCustomVisitTypeVariables,
} from './__generated__/GetVisitDocumentForGuestWithCustomVisitType';

export const GET_VISIT_DOCUMENT = gql`
  query GetVisitDocumentForGuestWithCustomVisitType(
    $documentId: ID!
    $visitType: String!
    $visitLocationId: ID!
    $visitInviteId: ID!
  ) {
    visitDocumentWithNextDocumentIdWithCustomVisitType(
      documentId: $documentId
      visitType: $visitType
      visitLocationId: $visitLocationId
      visitInviteId: $visitInviteId
    ) {
      id
      documentName
      documentBody
      possibleOutcomes {
        id
        outcome
      }
      completionStatus
      workflowId
    }
  }
`;

export const useDocumentDetails = (
  visitType?: string,
  visitLocationId?: string,
  visitInviteId?: string,
  documentId?: string,
  options?: QueryHookOptions<
    GetVisitDocumentForGuestWithCustomVisitType,
    GetVisitDocumentForGuestWithCustomVisitTypeVariables
  >
): QueryResult<GetVisitDocumentForGuestWithCustomVisitType> => {
  return useQuery<
    GetVisitDocumentForGuestWithCustomVisitType,
    GetVisitDocumentForGuestWithCustomVisitTypeVariables
  >(GET_VISIT_DOCUMENT, {
    ...options,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !visitType || !visitLocationId || !visitInviteId || !documentId,
    variables: {
      documentId: documentId ?? '',
      visitType: visitType ?? '',
      visitLocationId: visitLocationId ?? '',
      visitInviteId: visitInviteId ?? '',
    },
  });
};
