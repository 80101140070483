import { createContext, useCallback, useContext, useMemo } from 'react';
import Amplitude, { AmplitudeClient, Identify } from 'amplitude-js';

type AmplitudeContextValue = {
  identify: (orgId: string, organizationSlug: string, userId: string) => void;
  trackEvent: (eventName: string, metadata?: Record<string, unknown>) => void;
};

const defaultFn = () =>
  // eslint-disable-next-line no-console
  console.warn('Tried to called a method before AmplitudeContext is ready');

const AmplitudeContext = createContext<AmplitudeContextValue>({
  identify: defaultFn,
  trackEvent: defaultFn,
});

const log = (message: string, ...args: unknown[]) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[Amplitude] ${message}`, ...args); // eslint-disable-line no-console
  }
};

export const AmplitudeProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const client = useMemo<AmplitudeClient>(() => {
    const client = Amplitude.getInstance('robin-product-metrics');
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
    if (apiKey) {
      client.init(apiKey);
    } else {
      log('Could not initialize; API key not found');
    }
    return client;
  }, []);

  const trackEvent = useCallback(
    (eventName: string, metadata?: Record<string, unknown>) => {
      log('Tracking event:', { eventName, metadata });
      client.logEvent(eventName, metadata);
    },
    [client]
  );

  const identify = useCallback(
    (orgId, organizationSlug, userId) => {
      const identify = new Identify();
      identify.set('organizationId', orgId);
      identify.set('organizationSlug', organizationSlug);
      client.setUserId(userId);
      client.setGroup('organizationId', orgId);
      client.setGroup('organizationSlug', organizationSlug);
      log('Setting user info:', { orgId, organizationSlug, userId });
      client.identify(identify);
      log('Client identity set');
    },
    [client]
  );

  const api = {
    identify,
    trackEvent,
  };

  return (
    <AmplitudeContext.Provider value={api}>
      {children}
    </AmplitudeContext.Provider>
  );
};

type UseAmplitude = () => {
  identify: (orgId: string, organizationSlug: string, userId: string) => void;
  trackPageLoaded: () => void;
  trackCheckIn: () => void;
};

export const useAmplitude: UseAmplitude = () => {
  const { identify, trackEvent } = useContext(AmplitudeContext);

  const trackPageLoaded = () => {
    trackEvent('loaded-guestui');
  };

  const trackCheckIn = () => {
    trackEvent('check-into-visit');
  };

  return {
    identify,
    trackPageLoaded,
    trackCheckIn,
  };
};
