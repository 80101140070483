import styled from '@emotion/styled/macro';
import { Button, Colors } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { PageSection } from './components/PageSection';
import { Section } from './components/Section';
import { SectionTitle } from './components/SectionTitle';

export const ServerError = ({
  onPressRetry,
}: {
  onPressRetry: () => unknown;
}): JSX.Element => {
  const { t } = useTranslation('visitDetails');
  return (
    <PageSection backgroundColor={Colors.White0}>
      <Section mt="4" light>
        <SectionTitle>{t('sections.error.generic.header')}</SectionTitle>
        {t('sections.error.generic.body')}
        <PrimaryButton variant="primary" onClick={() => onPressRetry()}>
          {t('try_again')}
        </PrimaryButton>
      </Section>
    </PageSection>
  );
};

const PrimaryButton = styled(Button)`
  width: 100%;
  margin-top: 2em;
  box-shadow: none;
`;
