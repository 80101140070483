import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Heading01,
  Heading04,
  Body04,
  Body06,
  ErrorBadge,
} from '@robinpowered/design-system';
import styled from '@emotion/styled/macro';
import { GetVisitQuery_getVisitByMagicLink as VisitData } from '../../contexts/__generated__/GetVisitQuery';
import {
  GenericHealthCheckpointStatus,
  GuestInviteStatus,
} from '__generated__/globalTypes';
import {
  useCurrentTime,
  useCheckInMutation,
  ActionItem,
  useActions,
  Status,
} from './hooks';
import { ActionItemsLoadingUI, ActionItemsSection } from './ActionItemsSection';
import { CheckInButton } from './CheckInButton';
import { AvatarMessage } from './AvatarMessage';
import { AvatarNameEmail } from './AvatarNameEmail';

enum State {
  ACTIONS_NEEDED = 'ACTIONS_NEEDED', // Need to complete one or more action items
  FAILED_HEALTH_CHECK = 'FAILED_HEALTH_CHECK', // Failed the health check, ineligible to check in.
  CHECK_IN_TOO_EARLY_ACTIONS_COMPLETED = 'CHECK_IN_TOO_EARLY_ACTIONS_COMPLETED', // Outside check-in widow
  CAN_CHECK_IN = 'CAN_CHECK_IN', // Action needed: Press the check-in button
  CHECKED_IN = 'CHECKED_IN', // Successfully checked in. Either passed HCP or HCP wasn't required.
  CHECKED_OUT = 'CHECKED_OUT', // Successfully checked out after checking in.
  CHECKED_IN_PENDING_ACTIONS = 'CHECKED_IN_PENDING_ACTIONS',
}

const getState = (
  visit: VisitData,
  currentTime: moment.Moment,
  actions: ActionItem[]
): State => {
  const allActionsCompleted = actions.every(
    (action) => action.status === Status.COMPLETED
  );
  const timezone = visit.arrivalLocation.timezone ?? moment.tz.guess();
  const visitStart = moment.tz(visit.startTime, timezone);
  const checkInWindowOpens = visitStart.clone().subtract(30, 'minutes');

  const healthCheckStatus = visit.healthCheckpointSurveyResponse?.status;
  if (healthCheckStatus === GenericHealthCheckpointStatus.FAILED) {
    return State.FAILED_HEALTH_CHECK;
  } else if (
    visit.status === GuestInviteStatus.CHECKED_IN &&
    allActionsCompleted
  ) {
    return State.CHECKED_IN;
  } else if (
    visit.status === GuestInviteStatus.CHECKED_IN &&
    !allActionsCompleted
  ) {
    return State.CHECKED_IN_PENDING_ACTIONS;
  } else if (visit.status === GuestInviteStatus.CHECKED_OUT) {
    return State.CHECKED_OUT;
  } else if (!allActionsCompleted) {
    return State.ACTIONS_NEEDED;
  } else if (currentTime.isBefore(checkInWindowOpens) && allActionsCompleted) {
    return State.CHECK_IN_TOO_EARLY_ACTIONS_COMPLETED;
  } else {
    return State.CAN_CHECK_IN;
  }
};

export const VisitSummaryHeader = ({
  visit,
}: {
  visit: VisitData;
}): JSX.Element => {
  const { t } = useTranslation('visitDetails', {
    keyPrefix: 'sections.check_in',
  });
  const [checkInError, setCheckInError] = useState(false);
  const timezone = visit.arrivalLocation.timezone ?? moment.tz.guess();
  const startTimeWithTz = moment.tz(visit.startTime, timezone);
  const currentTime = useCurrentTime(startTimeWithTz);
  const { actionItems, loading } = useActions(visit);
  const currentState = getState(visit, currentTime, actionItems);
  const [checkIn, { loading: checkInLoading }] = useCheckInMutation({
    onError: () => setCheckInError(true),
    onCompleted: () => setCheckInError(false),
  });

  const hostName = visit.host.name || t('your_host');
  const hostEmail = visit.host.email || '';

  if (loading) {
    return <ActionItemsLoadingUI />;
  }

  // eslint-disable-next-line default-case
  switch (currentState) {
    case State.ACTIONS_NEEDED: {
      const checkInWindowOpens = startTimeWithTz
        .clone()
        .subtract(30, 'minutes');
      const isTooEarlyToCheckIn = currentTime.isBefore(checkInWindowOpens);
      return (
        <>
          <Heading>{t('actions_needed_heading')}</Heading>
          <ActionItemsSection visit={visit} />
          <CheckInButton disabled isLoading={false} />
          {isTooEarlyToCheckIn && (
            <Footer>{t('early_footer_incomplete_tasks')}</Footer>
          )}
        </>
      );
    }
    case State.FAILED_HEALTH_CHECK:
      return (
        <>
          <Heading>{t('hcp_failed_heading')}</Heading>
          <Body mb="24px">{t('hcp_failed_subheading')}</Body>
          <Subheading>{t('hcp_failed_body')}</Subheading>
          {hostEmail && (
            <AvatarNameEmail
              avatarSrc={visit.host.avatar ?? undefined}
              avatarName={hostName}
              avatarEmail={hostEmail}
            />
          )}
        </>
      );
    case State.CHECKED_IN:
      return (
        <>
          <Heading>{t('checked_in_heading')}</Heading>
          <Body>
            {t('checked_in_at', {
              time: moment(visit.checkinOn).format('h:mm a'),
            })}
          </Body>

          <AvatarMessage
            avatarSrc={visit.host.avatar ?? undefined}
            avatarName={hostName}
            message={t('checked_in_message', { hostName })}
          />
        </>
      );
    case State.CHECKED_OUT:
      return (
        <>
          <Heading>{t('checked_out_heading')}</Heading>
          <Body>
            {t('checked_out_at', {
              time: moment(visit.checkoutOn).format('h:mm a'),
            })}
          </Body>
        </>
      );
    case State.CHECK_IN_TOO_EARLY_ACTIONS_COMPLETED: {
      const checkInTime = startTimeWithTz
        .clone()
        .subtract(30, 'minutes')
        .format('h:mm a');
      const checkInDate = startTimeWithTz.format('MMM D');
      const earlyBody = moment().isSame(startTimeWithTz, 'day')
        ? t('early_body_today', { checkInTime })
        : t('early_body_future', { checkInTime, checkInDate });
      return (
        <>
          <Heading>{t('early_heading')}</Heading>
          <Body>{earlyBody}</Body>
          <CheckInButton disabled isLoading={false} />
          {actionItems.length > 0 && <ActionItemsSection visit={visit} />}
        </>
      );
    }
    case State.CAN_CHECK_IN:
      return (
        <>
          {checkInError && <ErrorBadge mb={4} message={t('notify_error')} />}
          <Heading>{t('check_in_heading')}</Heading>
          <Body>{t('check_in_body', { hostName })}</Body>
          <CheckInButton isLoading={checkInLoading} onPress={checkIn} />
          {actionItems.length > 0 && <ActionItemsSection visit={visit} />}
        </>
      );
    case State.CHECKED_IN_PENDING_ACTIONS:
      return (
        <>
          <Heading>{t('actions_needed_heading')}</Heading>
          <ActionItemsSection visit={visit} />
        </>
      );
  }
};

const Heading = styled(Heading01)`
  margin-bottom: 32px;
`;

const Subheading = styled(Heading04)`
  margin-bottom: 16px;
  white-space: pre-wrap; // Support for line breaks
`;

const Body = styled(Body04)`
  margin-top: -24px; /* Undo header margin, make it 8px */
  margin-bottom: 32px;
`;

const Footer = styled(Body06)`
  margin-top: 16px;
  text-align: center;
`;
