import { useTranslation } from 'react-i18next';
import { Heading04, Body05, Button } from '@robinpowered/design-system';

type ErrorMessageProps = {
  retry: () => unknown;
  title?: string;
  message: string;
};

/**
 * An error message to be shown when a network error occurs.
 * Includes a title, subtitle and a retry button.
 */
export const ErrorWithRetry: React.FC<ErrorMessageProps> = ({
  retry,
  message,
  title,
}) => {
  const { t } = useTranslation('common');
  return (
    <>
      <Heading04 mt="48px" mb="8px">
        {title ?? t('somethings_wrong')}
      </Heading04>
      <Body05
        mb="12px"
        mx="12px"
        // Whitespace:pre enables string line breaks
        style={{ textAlign: 'center', whiteSpace: 'pre' }}
      >
        {message}
      </Body05>
      <Button variant="secondary" onClick={() => retry()} mb="48px">
        {t('retry')}
      </Button>
    </>
  );
};
