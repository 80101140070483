import { Avatar, Colors, Body04 } from '@robinpowered/design-system';
import { MessageContainer } from './MessageContainer';

type AvatarMessageProps = {
  avatarSrc?: string;
  avatarName: string;
  message: string;
};

export const AvatarMessage: React.FC<AvatarMessageProps> = ({
  avatarSrc,
  avatarName,
  message,
}) => (
  <MessageContainer>
    {/* Wrapping in a div avoids distortion when window is narrow */}
    <div>
      <Avatar name={avatarName} src={avatarSrc} size={52} />
    </div>
    <Body04 ml="16px" color={Colors.Gray100}>
      {message}
    </Body04>
  </MessageContainer>
);
