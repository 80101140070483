import { createContext, useContext } from 'react';
import { gql, useQuery, ApolloError } from '@apollo/client';
import {
  GetVisitQuery_getVisitByMagicLink,
  GetVisitQuery,
  GetVisitQueryVariables,
} from './__generated__/GetVisitQuery';

export const GET_VISIT_QUERY = gql`
  query GetVisitQuery($uuid: String!) {
    getVisitByMagicLink(magicLink: $uuid) {
      id
      visitId
      startTime
      customVisitType
      visitType
      arrivalInstructions
      status
      checkinOn
      checkoutOn
      arrivalLocation {
        id
        name
        address
        timezone
        isHealthCheckpointRequired
      }
      host {
        id
        name
        avatar
        email
      }
      guest {
        id
        name
      }
      organization {
        id
        logo
        slug
      }
      healthCheckpointSurveyResponse {
        id
        status
      }
    }
  }
`;

type VisitDetailsContextValue = {
  data: GetVisitQuery_getVisitByMagicLink | null;
  tenantId: string | null;
  refetch: () => void;
  loading: boolean;
  error?: ApolloError;
};

const VisitDetailsContext = createContext<VisitDetailsContextValue | undefined>(
  undefined
);

export const VisitDetailsProvider = ({
  tenantId,
  magicLinkUuid,
  children,
}: {
  tenantId: string | null;
  magicLinkUuid?: string;
  children: JSX.Element;
}): JSX.Element => {
  const {
    loading: isVisitLoading,
    error: visitError,
    data,
    refetch,
  } = useQuery<GetVisitQuery, GetVisitQueryVariables>(GET_VISIT_QUERY, {
    skip: !tenantId || !magicLinkUuid,
    notifyOnNetworkStatusChange: false, // Avoid flashing when the page refreshes on focus
    variables: {
      uuid: magicLinkUuid ?? '',
    },
  });

  return (
    <VisitDetailsContext.Provider
      value={{
        data: data?.getVisitByMagicLink ?? null,
        tenantId,
        refetch: refetch,
        loading: isVisitLoading,
        error: visitError,
      }}
    >
      {children}
    </VisitDetailsContext.Provider>
  );
};

export const useVisitDetailsContext = (): VisitDetailsContextValue => {
  const context = useContext(VisitDetailsContext);
  if (!context) {
    throw new Error(
      'useVisitDetailsContext must be used within a VisitDetailsProvider'
    );
  }
  return context;
};
