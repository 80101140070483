import {
  gql,
  useMutation,
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client';
import {
  GetAuthForMagicLink,
  GetAuthForMagicLinkVariables,
} from './__generated__/GetAuthForMagicLink';
import { useApolloContext } from 'contexts';
import { useEffect } from 'react';

const REFRESH_TOKEN_INTERVAL_MINS = 1000 * 60 * 30; // 30 minutes

const GET_AUTH_TOKEN = gql`
  mutation GetAuthForMagicLink($uuid: String!) {
    authenticateVisitorWithMagicLink(magicLink: $uuid) {
      id
      token
      tenantId
    }
  }
`;

export function useAuthentication(
  magicLinkUuid?: string,
  options?: MutationHookOptions<
    GetAuthForMagicLink,
    GetAuthForMagicLinkVariables
  >
): MutationTuple<GetAuthForMagicLink, GetAuthForMagicLinkVariables> {
  const { setAuthToken, setTenantId } = useApolloContext();
  const mutation = useMutation<
    GetAuthForMagicLink,
    GetAuthForMagicLinkVariables
  >(GET_AUTH_TOKEN, {
    ...options,
    variables: {
      uuid: magicLinkUuid ?? '',
    },
    errorPolicy: 'all', // Don't throw when auth fails
  });

  const [authenticateMutation, { data }] = mutation;
  const dataResponse = data?.authenticateVisitorWithMagicLink;
  useEffect(() => {
    if (dataResponse) {
      setTenantId(dataResponse.tenantId);
      setAuthToken(dataResponse.token);
    }
  }, [dataResponse, setTenantId, setAuthToken]);

  // Keep refreshing the bearer token every couple minutes as soon as,
  // and as long as, a refresh token exists.
  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (dataResponse?.token) {
      timerId = setTimeout(async () => {
        await authenticateMutation();
      }, REFRESH_TOKEN_INTERVAL_MINS);
    }
    return () => clearTimeout(timerId);
  }, [dataResponse?.token]); // eslint-disable-line react-hooks/exhaustive-deps

  return mutation;
}
