import { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import styled from '@emotion/styled/macro';
import { Body06, Colors } from '@robinpowered/design-system';
import { Trans, useTranslation } from 'react-i18next';
import { useAmplitude } from 'contexts';
import { Loading } from './Loading';
import { NotFound } from './NotFound';
import { ServerError } from './ServerError';
import { useVisitDetailsContext } from './contexts/VisitDetailsContext';
import { GetVisitQuery_getVisitByMagicLink } from './contexts/__generated__/GetVisitQuery';
import {
  VisitSummaryHeader,
  VisitFields,
  Section,
  SectionTitle,
  PageSection,
  LanguageSelector,
} from './components';

const usePageLoadEvent = (
  loading: boolean,
  data: null | undefined | GetVisitQuery_getVisitByMagicLink,
  error?: ApolloError
) => {
  const { identify, trackPageLoaded } = useAmplitude();

  useEffect(() => {
    if (loading || error || !data) {
      return;
    }

    identify(
      data.organization.id,
      data.organization.slug ?? 'unknown',
      data.guest.id
    );
    trackPageLoaded();
  }, [data, loading, error, identify, trackPageLoaded]);
};

export const VisitDetails: React.FC<{ isParentLoading: boolean }> = ({
  isParentLoading,
}): JSX.Element => {
  const {
    loading: visitDetailsLoading,
    error: visitDetailsError,
    data,
    refetch,
  } = useVisitDetailsContext();
  const { t } = useTranslation('visitDetails');
  usePageLoadEvent(visitDetailsLoading, data, visitDetailsError);
  // Refresh the status when the page gets refocused.
  // Helpful for updating after filling out the health check
  // in another tab/window.
  useEffect(() => {
    window.addEventListener('focus', refetch);
    return () => {
      window.removeEventListener('focus', refetch);
    };
  }, [refetch]);

  if (visitDetailsLoading || isParentLoading) {
    return (
      <ScreenContainer>
        <Loading />
      </ScreenContainer>
    );
  }
  if (visitDetailsError) {
    return <ServerError onPressRetry={refetch} />;
  }
  if (!data) {
    return <NotFound />;
  }

  return (
    <ScreenContainer>
      <PageSection backgroundColor={Colors.White0}>
        <Header>
          {data.organization.logo && <Logo src={data.organization.logo} />}
          <LanguageSelector />
        </Header>
        <VisitSummaryHeader visit={data} />
      </PageSection>

      <PageSection>
        <Section mb="3" boxed>
          <SectionTitle>{t('sections.details.header')}</SectionTitle>
          <VisitFields visit={data} />
        </Section>
        {data.arrivalInstructions && (
          <Section boxed>
            <SectionTitle>{t('sections.instructions.header')}</SectionTitle>
            {data.arrivalInstructions}
          </Section>
        )}
        <Footer>
          {/* Value in `Trans` must match the translation file so don't let prettier mess things up */}
          {/* prettier-ignore */}
          <Trans t={t} i18nKey="footer">
            Robin needs the information you provide in order to manage your visit.
          </Trans>
        </Footer>
      </PageSection>
    </ScreenContainer>
  );
};

const ScreenContainer = styled.div`
  min-height: 100%;
  background-color: ${Colors.Tan10};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Footer = styled(Body06)`
  display: block;
  margin-top: 32px;
  color: ${Colors.Gray100};
  text-align: center;
  line-height: 1.4;

  a {
    color: inherit;
  }
`;

const Logo = styled.img`
  max-height: 50px;
`;
