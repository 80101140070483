import {
  useMutation,
  MutationTuple,
  MutationHookOptions,
} from '@apollo/client';
import { gql } from 'graphql-tag';
import {
  DecideOutcome,
  DecideOutcomeVariables,
} from './__generated__/DecideOutcome';

const DOCUMENT_AGREEMENT_MUTATION = gql`
  mutation DecideOutcome(
    $visitId: ID!
    $visitInviteId: ID!
    $guestId: ID!
    $workflowId: ID!
    $outcomeId: ID!
    $sendCopyToGuest: Boolean
  ) {
    decideOutcomeForVisitDocumentFromGuestUI(
      visitId: $visitId
      visitInviteId: $visitInviteId
      guestId: $guestId
      workflowId: $workflowId
      outcomeId: $outcomeId
      sendCopyToGuest: $sendCopyToGuest
    )
  }
`;

export const useAgreeToDocument = (
  options?: MutationHookOptions<DecideOutcome, DecideOutcomeVariables>
): MutationTuple<DecideOutcome, DecideOutcomeVariables> => {
  return useMutation<DecideOutcome, DecideOutcomeVariables>(
    DOCUMENT_AGREEMENT_MUTATION,
    options
  );
};
